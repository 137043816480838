<template>
	<div id="app">
		<AppNavbar />
		<router-view />
		<AppFooter />
	</div>
</template>

<script>
import AppNavbar from './views/layout/components/AppNavbar.vue';
import AppFooter from './views/layout/components/AppFooter.vue';

export default {
	components: {
		AppNavbar,
		AppFooter
	}
};
</script>
<style lang="scss">
body {
	margin: 0;
}
#app {
	margin: 0 auto;
}
</style>
