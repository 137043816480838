<template>
	<div class="app-navbar">
		<div class="logoBox">
			<div class="logo"><img src="/img/base/logo.png" alt="" /></div>
		</div>
		<AppMenu />
	</div>
</template>

<script>
import AppMenu from './components/AppMenu.vue';
export default {
	components: {
		AppMenu
	}
};
</script>

<style lang="scss" scoped>
.app-navbar {
	padding: 14px 0;
	margin: 0 auto;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	.logoBox {
		padding-left: 10%;
		.logo {
			width: 106px;
			height: 64px;
			margin: 0 auto;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
