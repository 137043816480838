<template>
	<div class="app-menu">
		<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
			<el-menu-item v-for="menu in menus" :key="menu.code" :index="menu.code">{{ menu.title }}</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
export default {
	data() {
		return {
			menus: [
				{
					code: '0',
					title: '产品介绍',
					path: 'index'
				},
				{
					code: '1',
					title: '企业介绍',
					path: 'companiesIntroduce'
				},
				{
					code: '2',
					title: '联系我们',
					path: 'contact'
				}
			]
		};
	},
	computed: {
		// 刷新页面，保持当前页面高亮
		activeIndex() {
			let routeName = this.$route.name;
			let menus = this.menus;
			let code = '';
			menus.forEach(el => {
				if (el.path == routeName) {
					code = el.code;
				}
			});
			return code || null;
		}
	},
	methods: {
		handleSelect(key) {
			let path = this.menus[key].path;
			this.$router.push({ name: path });
		}
	}
};
</script>

<style lang="scss" scoped>
.app-menu {
	width: 100%;
	height: 100%;
	.el-menu--horizontal {
		border: none;
		width: 100%;
		display: flex;
		justify-content: space-between;
		.el-menu-item {
			font-size: 22px;
			height: 64px;
			line-height: 64px;
			color: #424242;
			font-weight: bold;
			&.is-active {
				border-color: #424242;
			}
		}
	}
}
</style>
