import Vue from "vue";
import VueRouter from "vue-router";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
	// {
	// 	path: "/",
	// 	name: "layout",
	// 	component: () => import('@/views/layout/layout.vue'),
	// },
	{
		path: "/",
		name: "index",
		component: () => import('@/views/index/index.vue'),
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import('@/views/contact/contact.vue'),
	},
	{
		path: "/companiesIntroduce",
		name: "companiesIntroduce",
		component: () => import('@/views/companiesIntroduce/companiesIntroduce.vue'),
	}
];

const router = new VueRouter({
	routes,
});

export default router;
